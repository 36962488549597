import React, { createContext, useContext, useEffect, useState } from "react"
import { fetchRest } from "@lib/fetchRest"

const FeatureFlagContext = createContext()

const CACHE_KEY = "featureFlags"
const CACHE_DURATION = 1800000 // 30 minutes in milliseconds

export function FeatureFlagProvider({ children, context, clientSideID, options = {} }) {
    const [flags, setFlags] = useState({})

    useEffect(() => {
        const fetchAndSetFlags = async () => {
            const { data } = await fetchRest(
                "feature-flags",
                {
                    fields: ["id", "featureName"],
                    populate: {
                        enabledUsernames: {
                            fields: ["value"]
                        }
                    },
                    filters: {
                        $or: [
                            {
                                enabledForAll: {
                                    $eq: true
                                }
                            },
                            {
                                enabledUsernames: {
                                    value: {
                                        $eq: context.key ?? "anonymous"
                                    }
                                }
                            }
                        ]
                    },
                    pagination: {
                        limit: 100
                    }
                },
                undefined,
                1800 // 30 min cache
            )

            const fetchedFlags = {}
            if (Array.isArray(data)) {
                data.forEach((item) => {
                    if (item && typeof item.featureName === "string") {
                        fetchedFlags[item.featureName] = true
                    }
                })
            }

            setFlags(fetchedFlags)
            return fetchedFlags
        }

        const loadFlags = async () => {
            if (options.bootstrap === "localStorage") {
                const cachedData = localStorage.getItem(CACHE_KEY)
                if (cachedData) {
                    const { flags: cachedFlags, timestamp } = JSON.parse(cachedData)
                    if (Date.now() - timestamp < CACHE_DURATION) {
                        setFlags(cachedFlags)
                        return
                    }
                }
            }

            const newFlags = await fetchAndSetFlags()

            if (options.bootstrap === "localStorage") {
                try {
                    localStorage.setItem(CACHE_KEY, JSON.stringify({ flags: newFlags, timestamp: Date.now() }))
                } catch (error) {
                    console.error("Error storing flags in localStorage:", error)
                }
            }
        }

        if (options.bootstrap !== "eventsOnly") {
            loadFlags()
        }

        if (options.streaming) {
            const interval = setInterval(loadFlags, 30000)
            return () => clearInterval(interval)
        }
    }, [context, clientSideID, options])

    return <FeatureFlagContext.Provider value={flags || {}}>{children}</FeatureFlagContext.Provider>
}

export function useFlags() {
    return useContext(FeatureFlagContext)
}
